<template>
  <div :class="{disabled: disabled}">
    <div
      v-if="filePreview"
      class="thumbnail-container"
    >
      <img
        :id="previewId"
        :key="filePreview"
        :hidden="!filePreview"
        class="image"
        src="@/assets/icons/form_description_file.svg"
        style="max-width: 100px; margin: 1em;"
        alt="Icon form description file"
      >
    </div>
    <div v-if="fileExist || filePreview">
      <div
        v-if="fileExist && !filePreview"
        class="thumbnail-container"
      >
        <img
          class="image"
          src="@/assets/icons/form_description_file.svg"
          style="max-width: 100px; margin: 1em 1em 0.3em;"
          alt="Icon form description file"
        >
        <div class="middle">
          <b-icon
            icon="arrow-down-circle-fill"
            variant="success"
            font-scale="2"
            @click="downloadFile"
          />
        </div>
        <p style="margin: 0 0 0.5em 1.3em">
          {{ title.split('/').slice(-1)[0] }}
        </p>
      </div>
    </div>
    <div
      v-else
      id="import-file"
      @click="uploadFile"
    >
      <img
        src="@/assets/icons/file_document_sheet.svg"
        alt="Icon file document sheet"
      >
      <div>
        <b-icon-plus />
        {{ $t('importFile.selectFile') }}
      </div>
    </div>
    <div>
      <!-- eslint-disable -->
      <ValidationProvider
        id="file-upload"
        ref="provider"
        v-slot="{ classes, errors, validate }"
        :name="`${$t('importFile.label')} ${name}`"
        :rules="`${required ? 'required' : ''}`"
      >
        <div
          class="control"
          :class="required ? classes : ''"
        >
          <input
            :id="`upload-file-${name}`"
            :hidden="!(fileExist || filePreview)"
            type="file"
            :accept="accept"
            :placeholder="$t('importFile.infos')"
            @change="previewFile"
          >
          <span class="form-errors">{{ errors[0] }}</span>
        </div>
      </ValidationProvider>
       <!-- eslint-enable -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, extend, configure } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'Ce champ est requis'
});
configure({
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid'
  }
});

export default {
  name: 'ImportFile',

  components: {
    ValidationProvider
  },

  props: {
    name: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'ressource'
    },
    fileName: {
      type: String,
      default: null
    },
    file: {
      type: FormData,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default: '*'
    },
    required: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      fileExist: false,
      filePreview: false,
      fileInputValue: null
    };
  },

  computed: {
    previewId() {
      return `preview-file-${this.name}`;
    },
    fileURL() {
      if (this.fileName) {
        return new URL(this.fileName, process.env.VUE_APP_DOMAIN);
      } else {
        return '';
      }
    }
  },

  watch: {
    accept(newValue, oldValue) {
      if (newValue || (!newValue && oldValue)) {
        this.filePreview = false;
      }
    }
  },

  created() {
    if (this.fileName) {
      this.fileExist = true;
    }
  },

  methods: {
    uploadFile() {
      const elem = document.getElementById(`upload-file-${this.name}`);
      elem.click();
    },
    async previewFile(e) {
      const { valid } = await this.$refs.provider.validate(e);

      if (e.target.files && e.target.files[0] && valid) {
        this.fileExist = false;
        this.filePreview = true;
      }
      this.$emit('update', e.target.files[0]);
    },

    downloadFile() {
      const link = document.createElement('a');
      link.href = this.fileURL;
      link.setAttribute('download', `${this.fileName.split('/').slice(-1)[0]}`);
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }
};
</script>

<style lang="less" scoped>

#import-file {
  cursor: pointer;
  margin-top: 0.5em;
  padding: 0 0.5rem;
  border: 1px dashed @blue;
  border-radius: 3px;
  max-width: 240px;
  height: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-right: 1em;
  img {
    height: 2.5em;
  }
  div {
    color: @blue;
    font-size: 1.1em;
    font-weight: bold;
    font-style: normal;
    text-align: center;
  }
}
#import-file:hover {
  border: 2px dashed @blue;
  img {
    height: 2.7em;
  }
  div {
    font-size: 1.3em;
    text-align: center;

  }
}

#preview-file {
  max-width: 15em;
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.thumbnail-container {
  position: relative;
  width: 120px;
}

.image {
  opacity: 1;
  display: block;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}

.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.top-right {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 10%;
  left: 90%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.thumbnail-container:hover .image {
  opacity: 0.35;
}

.thumbnail-container:hover .middle {
  cursor: pointer;
  opacity: 1;
}

.thumbnail-container:hover .top-right {
  opacity: 1;
}

</style>
