<template>
  <div class="markdown_container">
    <div
      class="btn-group btn-group-sm"
      role="group"
      style="z-index: 0;"
    >
      <input
        id="btnradio1"
        :checked="!advancedEditorOn"
        type="radio"
        class="btn-check"
        autocomplete="off"
        @click="setEditorMode(false)"
      >
      <label
        class="btn btn-outline-primary"
        for="btnradio1"
      >
        {{ $t('forms.datasets.editor.basic') }}
      </label>

      <input
        id="btnradio2"
        :checked="advancedEditorOn"
        type="radio"
        class="btn-check"
        autocomplete="off"
        @click="setEditorMode(true)"
      >
      <label
        class="btn btn-outline-primary"
        for="btnradio2"
      >
        {{ $t('forms.datasets.editor.advanced') }}
      </label>
    </div>
    <Editor
      v-if="advancedEditorOn"
      ref="toastuiEditor"
      :key="`toast-ui-editor-${editorKey}`"
      :initial-value="mdText"
      initial-edit-type="wysiwyg"
      :options="editorOptions"
      :class="classes"
      class="markdown_editor"
      @change="getMarkdown"
    />
    <textarea
      v-else
      v-model="mdText"
      v-sanitize
      :class="classes"
      class="control form-control"
    />
  </div>
</template>

<script>
// import '@toast-ui/editor/dist/toastui-editor.css';
import '@toast-ui/editor/dist/i18n/fr-fr.js';
import { Editor } from '@toast-ui/vue-editor';

export default {
  name: 'MarkdownEditor',

  components: {
    Editor
  },

  props: {
    value: {
      type: String,
      default: null
    },
    classes: {
      type: Object,
      default: () => { 
        return {
          valid: 'is-valid',
          invalid: 'is-invalid'
        };
      }
    }
  },

  data() {
    return {
      advancedEditorOn: true,
      editorKey: 1,
      mdText: '',
      mdHtml: null,
      editorOptions: {
        hideModeSwitch: true,
        language: 'fr-FR',
        useCommandShortcut: false,
        toolbarItems: [
          ['heading', 'bold', 'italic', 'strike'],
          ['hr', 'quote'],
          ['ul', 'ol', 'task', 'indent', 'outdent'],
          ['link'],
        ]
      }
    };
  },

  watch: {
    mdText(newValue) {
      this.$emit('write', newValue);
    },

    value(newValue) {
      if (newValue !== this.mdText) {
        this.mdText = newValue;
        this.editorKey += 1;
      }
    }
  },

  created() {
    if (this.value) {
      this.mdText = this.value;
    }
  },

  methods: {
    setEditorMode(e) {
      this.advancedEditorOn = e;
    },

    getMarkdown() {
      this.mdText = this.$refs.toastuiEditor.invoke('getMarkdown');
    }
  }
};
</script>

<style scoped lang="less">
.markdown_container {
  position: relative;
  margin-top: 0.5rem;
  .btn-group {
    position: absolute;
    right: 0;
    top: -2rem;
    .btn {
      font-size: 0.75rem;
      padding: 0.2rem 0.4rem;
    }
    .btn:first-of-type {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    .btn-check:checked + .btn {
      background-color: #187CC6;
      border-color: #187CC6;
    }
  }
  textarea {
    min-height: 300px;
  }
  .markdown_editor {
    min-height: 300px;
    resize: vertical;
    overflow-y: auto;
    border-radius: 4px;
  }
  .markdown_editor.is-valid {
    border: 2px #078b40 solid;
  }
  .markdown_editor.is-invalid {
    border: 2px #EB0600 solid;
  }
}
</style>