<template>
  <div
    :class="{disabled: disabled}"
    class="import-image"
  >
    <div class="container">
      <div
        v-if="filePreview"
        class="overlay-icon"
      >
        <i
          class="bi bi-trash-fill delete-icon"
          @click="removeFile"
        />
        <i
          class="bi bi-file-earmark-arrow-up-fill upload-icon"
          @click="uploadFile"
        />
      </div>
      <img
        :id="previewId"
        :key="previewKey"
        :hidden="!filePreview"
        class="image"
        alt="Image preview"
      />
    </div>
    <br v-if="filePreview">
    <div v-if="fileExist || filePreview">
      <div
        v-if="fileExist && !filePreview"
        class="display"
      >
        <div class="container">
          <div
            v-if="fileExist"
            class="overlay-icon"
          >
            <i
              class="bi bi-trash-fill delete-icon"
              @click="removeFile"
            />
            <i
              class="bi bi-file-earmark-arrow-up-fill upload-icon"
              @click="uploadFile"
            />
          </div>
          <img
            :src="fileURL"
            class="image"
            alt="Image preview"
          />
        </div>
        <p
          v-if="showFileName"
          style="margin: 0 0 0.5em 1.3em">
          {{ title.split('/').slice(-1)[0] }}
        </p>
      </div>
    </div>
    <div
      v-else
      id="import-file"
      @click="uploadFile"
    >
      <i class="bi bi-image"></i>
      <div v-if="type === 'logo'"><b-icon-plus />{{ $t('words.addLogo') }}</div>
      <div v-if="type === 'image'"><b-icon-plus />{{ $t('words.addThumbnail') }}</div>
    </div>
    <div>
      <input
        :id="`upload-file-${this.name}`"
        :hidden="!(fileExist || filePreview)"
        class="inputfile"
        name="inputfile"
        type="file"
        accept="image/*"
        :placeholder="$t('words.uploadFile')"
        @change="previewFile"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImportImage',

  props: {
    name: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'logo'
    },
    file: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showFileName: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      fileExist: false,
      filePreview: false,
      previewKey: 1
    }
  },

  computed: {
    previewId() {
      return `preview-file-${this.name}`;
    },
    fileURL() {
      if (this.file) {
        return new URL(this.file, process.env.VUE_APP_DOMAIN);
      } else {
        return ''
      }
    }
  },

  watch: {
    accept(newValue, oldValue) {
      if (newValue || (!newValue && oldValue)) {
        this.filePreview = false;
      }
    },
    file(newValue) {
      if (newValue) {
        this.fileExist = true;
      }
    }
  },

  created() {
    if (this.file) {
      this.fileExist = true;
    }
  },

  methods: {
    uploadFile() {
      const elem = document.getElementById(`upload-file-${this.name}`);
      elem.click();
    },
    previewFile(e) {
      const previewEl = document.getElementById(this.previewId);
      if (e.target.files && e.target.files[0]) {
        this.fileExist = false;
        this.filePreview = true;
        previewEl.src = URL.createObjectURL(e.target.files[0]);
        previewEl.onload = function() {
          URL.revokeObjectURL(previewEl.src) // free memory
        }
        this.$emit('update', e.target.files[0]);
      }
    },
    removeFile() {
      document.getElementById(`upload-file-${this.name}`).value = '';
      document.getElementById(this.previewId).src = '';
      this.fileExist = false;
      this.filePreview = false;
      this.$emit('update', null);
    }
  }
}
</script>

<style lang="less" scoped>

#import-file {
  cursor: pointer;
  margin: 0.5em;
  border: 1px dashed @blue;
  border-radius: 3px;
  width: 200px;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  i {
    color: @blue;
    font-size: 3rem;
    line-height: 1rem;
  }
  div {
    color: @blue;
    font-size: 1em;
    font-weight: bold;
    font-style: normal;
  }
}
#import-file:hover {
  border: 2px dashed @blue;
  img {
    height: 2.5em;
  }
  div {
    font-size: 1.1em;

  }
}

#preview-file {
  max-width: 15em;
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.import-image {
  max-width: fit-content;
}
.container {
  position: relative;
  max-width: fit-content;
  .image {
    transition: .5s ease;
    backface-visibility: hidden;
    width: 200px;
    height: 150px;
    object-fit: cover;
    box-shadow: 0 0 1px 1px #ccc;
    border-radius: 4px;
    margin: 0.5rem;
  }
  .overlay-icon {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    width: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    i:hover {
      transform: scale(1.2)
    }
    .delete-icon {
      font-size: 2rem;
      color: rgb(197, 0, 0);
    }
    .upload-icon {
      font-size: 2rem;
      color: @blue;
    }
  }
}
.display {
  margin-bottom: 0;
}

.container:hover .image {
  opacity: 0.3;
}
.container:hover .overlay-icon {
  cursor: pointer;
  opacity: 1;
}

</style>
