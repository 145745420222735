<template>
  <div class="dataset-contact">
    <b-overlay
      no-wrap
      :show="loading"
      :variant="'white'"
      :opacity="0.7"
      :blur="'2px'"
      rounded="sm"
    />
    <img
      v-if="allowDelete"
      class="delete-icon"
      src="@/assets/icons/trash_delete.svg"
      alt="Icône poubelle"
      @click.stop="$emit('delete', formData.order)"
    >
    <form>
      <div
        v-if="showMain"
        class="form-row radio_input"
      >
        <input
          :id="`contact-${formData.id}-main-contact-input`"
          :key="`contact-${formData.order}-radio-${formData.main.toString()}`"
          :checked="formData.main === true"
          type="radio"
          name="main-contact"
          @click="setMainContact"
        >
        <label
          for="main-contact"
        >
          Contact principal
        </label>
      </div>
      <div class="form-row">
        <div class="form-group col-7">
          <label class="required">
            {{ $t('forms.contact.name') }}
          </label>
          <ValidationProvider
            v-slot="{ classes, errors }"
            :name="`Nom contact ${formData.order}`"
            rules="required"
          >
            <input
              v-model="formData.contact.name"
              v-sanitize
              :class="classes"
              class="form-control"
              type="text"
              :placeholder="$t('forms.contact.name')"
              @blur="handleBlur"
            >
            <span class="form-errors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="form-group col-4">
          <label>
            {{ $t('forms.contact.position') }}
          </label>
          <ValidationProvider
            v-slot="{ classes, errors }"
            :name="`Position contact ${formData.order}`"
          >
            <input
              v-model="formData.contact.position"
              v-sanitize
              :class="classes"
              class="form-control"
              type="text"
              :placeholder="$t('forms.contact.position')"
              @blur="handleBlur"
            >
            <span class="form-errors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-7">
          <label class="required">
            {{ $t('forms.contact.email') }}
          </label>
          <ValidationProvider
            v-slot="{ classes, errors }"
            :name="`Email contact ${formData.order}`"
            rules="required|email"
          >
            <input
              v-model="formData.contact.email"
              v-sanitize
              :class="classes"
              class="control form-control"
              type="text"
              :placeholder="$t('forms.contact.email')"
              @blur="handleBlur"
            >
            <span class="form-errors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="form-group col-4">
          <label>
            {{ $t('forms.contact.phone') }}
          </label>
          <input
            v-model="formData.contact.phone_number"
            v-sanitize
            class="control form-control"
            type="text"
            :placeholder="$t('forms.contact.phone')"
            @blur="handleBlur"
          >
        </div>
      </div>
      <div
        v-if="!usergroupDisabled"
        class="form-row"
      >
        <div class="form-group col-7">
          <label class="required">
            {{ $t('forms.contact.usergroup.label') }}
          </label>
          <!-- <p>
            Rechercher un groupe d'utilisateurs dans la barre de recherche ci-dessous
          </p> -->
          <ValidationProvider
            v-slot="{ classes, errors }"
            :rules="usergroupDisabled ? '' : 'required'"
            :name="`Groupe contact ${formData.order}`"
          >
            <div
              class="control"
              :class="classes"
            >
              <SearchUsergroups
                v-model="formData.contact.usergroup"
                :types="[]"
                :placeholder="$t('forms.contact.usergroup.placeholder')"
                :usergroup="formData.contact.usergroup"
                :reset-after="false"
                :limit="100"
                :allow-null="true"
                :disabled="usergroupDisabled"
                style="margin-top: -0.5em"
                @select="setUsergroup"
                @close="setUsergroup"
              />
              <span class="form-errors">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </div>
        <div class="form-group col-4">
          <label>
            {{ $t('forms.contact.role') }}
          </label>
          <Multiselect
            v-model="formData.role"
            :options="datasetsRoles"
            track-by="value"
            label="label"
            select-label=""
            selected-label=""
            deselect-label=""
            :searchable="false"
            :placeholder="$t('forms.contact.role')"
            @blur="handleBlur"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import usergroupsAPI from '@/api/usergroupsAPI.js';

import Multiselect from 'vue-multiselect';
import SearchUsergroups from '@/components/Searchbars/SearchUsergroups.vue';


import { ValidationProvider, extend, configure, setInteractionMode } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'Ce champ est requis'
});
extend('email', {
  ...email,
  message: 'Veuillez entrer une adresse e-mail valide'
});
configure({
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid'
  }
});
setInteractionMode('passive');

export default {
  name: 'DatasetContactForm',

  components: {
    Multiselect,
    SearchUsergroups,
    ValidationProvider
  },

  props: {
    contact: {
      type: Object,
      default: () => {
        return {
          order: 1,
          id: null,
          role: null,
          main: false,
          contact: {
            id: null,
            name: null,
            position: null,
            email: null,
            usergroup: null,
            phone_number: null,
          }
        };
      }
    },
    currentContacts: {
      type: Array,
      default: () => {
        return [];
      }
    },
    usergroupDisabled: {
      type: Boolean,
      default: false
    },
    allowDelete: {
      type: Boolean,
      default: true
    },
    showMain: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      loading: false,
      formData: {
        order: 1,
        id: null,
        role: null,
        main: false,
        contact: {
          id: null,
          name: null,
          position: null,
          email: null,
          usergroup: null,
          phone_number: null,
        }
      }
    };
  },

  computed: {
    ...mapState('datasets', [
      'datasetsRoles',
    ])
  },

  watch: {
    formData: {
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.$emit('change', {
            order: this.formData.order,
            id: this.formData.id,
            role: this.formData.role,
            main: this.formData.main,
            contact: {
              id: this.formData.contact.id,
              name: this.formData.contact.name,
              position: this.formData.contact.position,
              email: this.formData.contact.email,
              phone_number: this.formData.contact.phone_number,
              usergroup: this.formData.contact.usergroup
            }
          });
        }
      }
    }
  },

  async created() {
    this.loading = true;
    this.formData.order = this.contact.order;
    this.formData.id = this.contact.id;
    this.formData.contact.id = this.contact.contact.id;
    if (this.contact.role) {
      this.formData.role = this.datasetsRoles.find(el => el.value === this.contact.role);
    }
    this.formData.main = this.contact.main;
    this.formData.contact.name = this.contact.contact.name;
    this.formData.contact.position = this.contact.contact.position;
    this.formData.contact.email = this.contact.contact.email;
    this.formData.contact.phone_number = this.contact.contact.phone_number;
    if (this.contact.contact.usergroup) {
      const usergroup =
        await usergroupsAPI.getUsergroup(this.contact.contact.usergroup.id ?
          this.contact.contact.usergroup.id :
          this.contact.contact.usergroup
        );
      if (usergroup) {
        this.formData.contact.usergroup = usergroup;
      }
    }
    this.loading = false;
  },

  methods: {

    setUsergroup(e) {
      this.formData.contact.usergroup = e;
    },

    handleBlur() {
      if (
        this.formData.contact.name !== null &&
        this.formData.contact.email !== null &&
        this.formData.contact.usergroup !== null
      ) {
        this.$emit('allowAdd', true);
      } else {
        this.$emit('allowAdd', false);
      }
    },

    setMainContact() {
      this.formData.main = !this.formData.main;
      this.$emit('set-main', this.formData.order);
    },
    unsetMainContact(order) {
      if (this.formData.order !== order) {
        this.formData.main = false;
      }
    }
  }
};
</script>

<style lang="less" scoped>

.dataset-contact {
  position: relative;
  box-shadow: 0 0 2px @blue;
  margin: 1rem 0;
  padding: 0.5rem 0;
  .delete-icon {
    cursor: pointer;
    position: absolute;
    top: 6%;
    left: calc(99% - 2em);
    width: 1.5em;
  }
  .delete-icon:hover {
    width: 2.2em;
    top: calc(5% - 0.1em);
    left: calc(99% - 2.1em);
  }
  .radio_input {
    margin-bottom: 0.5rem;
    input {
      margin: 0 0.5rem 0 0.4rem;
    }
  }
}

</style>
