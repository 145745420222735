<template>
  <div :class="disabled ? 'disabled' : ''">
    <Multiselect
      ref="multiselect"
      v-model="selection"
      style="margin-top: 0.5em;"
      class="search-usergroups"
      :disabled="disabled"
      :options="results"
      :options-limit="limit"
      :allow-empty="true"
      track-by="id"
      label="display_name"
      :reset-after="false"
      select-label=""
      selected-label=""
      deselect-label=""
      :searchable="true"
      :placeholder="placeholder"
      :show-no-results="true"
      :loading="loading"
      :clear-on-select="false"
      :preserve-search="true"
      @search-change="search"
      @select="select"
      @close="close"
    >
      <template slot="clear">
        <div
          v-if="selection"
          class="multiselect__clear"
          @click.prevent.stop="selection = null; $refs.multiselect.deactivate(); remove()"
        >
          <b-icon-x
            v-if="!disabled"
            font-scale="2"
          />
        </div>
      </template>
      <span slot="noResult">
        {{ $t('searchbars.noResult') }}
      </span>
      <span slot="noOptions">
        {{ $t('searchbars.noOptions') }}
      </span>
    </Multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

import { mapState, mapActions } from 'vuex';

export default {
  name: 'SearchUsergroups',

  components: {
    Multiselect
  },

  props: {
    usergroup: {
      type: Object,
      default: () => { return {}; }
    },
    currentUsergroups: {
      type: Array,
      default: () => {
        return [];
      }
    },
    types: {
      type: Array,
      default: () => { return []; }
    },
    resetAfter: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: 'Ajouter cette organisation à des groupes d\'organisations'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 10
    },
    preSelect: {
      type: Boolean,
      default: false
    },
    allowNull: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      selection: null,
      loading: false,
      text: null,
      results: []
    };
  },

  computed: {
    ...mapState('usergroups', [
      'usergroupsList',
      'groups',
      'spheres'
    ])
  },

  watch: {
    usergroup: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          if (newValue && newValue.usergroup && newValue.usergroup.id) {
            this.selection = newValue.usergroup;
          } else if (newValue && newValue.id) {
            this.selection = newValue;
          } else if (!newValue && this.allowNull) {
            this.selection = newValue;
          }
        }
      }
    },
    currentUsergroups: {
      deep: true,
      handler(newValue) {
        this.results = this.usergroupsList.filter(el => newValue.every(e => e.usergroup.id !== el.id));
      }
    },
    text: function(newValue) {
      this.loading = true;
      this.SEARCH_USERGROUPS_LIST({
        text: newValue,
        types: this.types,
        direction: '',
        field: 'display_name'
      })
        .then(() => {
          if (this.types.length === 1 && this.types[0] === 'user-group') {
            this.results = this.groups.filter(el => this.currentUsergroups.every(e => e.usergroup.id !== el.id));
          } else if (this.types.length === 1 && this.types[0] === 'group-of-organisation') {
            this.results = this.spheres.filter(el => this.currentUsergroups.every(e => e.usergroup.id !== el.id));
          } else {
            this.results =
              this.usergroupsList.filter(el => this.currentUsergroups.every(e => e.usergroup.id !== el.id));
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  },

  async created() {
    this.loading = true;
    await this.GET_USERGROUPS_LIST({
      direction: '',
      field: 'display_name',
      usergroupTypes: this.types,
      page: 1
    });
    this.results = this.usergroupsList.filter(el => this.currentUsergroups.every(e => e.usergroup.id !== el.id));
    if (this.usergroup && this.usergroup.usergroup && this.usergroup.usergroup.id) {
      this.selection = this.usergroup.usergroup;
    } else if (this.usergroup && this.usergroup.id) {
      this.selection = this.usergroup;
    } else if (this.results.length === 1 && this.preSelect) {
      this.selection = this.results[0];
      this.$emit('select', this.selection);
    }
    this.loading = false;
  },

  methods: {
    ...mapActions('usergroups', [
      'SEARCH_USERGROUPS_LIST',
      'GET_USERGROUPS_LIST'
    ]),

    search(text) {
      this.text = text;
    },

    select(e) {
      this.$emit('select', e);
    },
    close() {
      this.$emit('close', this.selection);
    },
    remove() {
      this.$emit('remove', this.selection);
    }
  }
};
</script>

<style lang="less" scoped>

.disabled {
  pointer-events: none;
  opacity: 0.6;
}

</style>
